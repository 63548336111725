import { useState, useEffect, useCallback } from 'react'

const EMPTY_ROWS = []

const useSelectedRows = <T,>(rows: T[]) => {
  const [selectedRows, setSelectedRows] = useState<T[]>(EMPTY_ROWS)

  const isSelected = useCallback((row: T) => selectedRows.indexOf(row) !== -1, [selectedRows])

  const selectRow = useCallback(
    (row: T) => {
      if (isSelected(row)) {
        setSelectedRows((prev) => prev.filter((selectedRow) => selectedRow !== row))
      } else {
        setSelectedRows((prev) => [...prev, row])
      }
    },
    [isSelected],
  )

  const selectAllRows = useCallback(() => {
    if (rows.length === selectedRows.length) {
      setSelectedRows(EMPTY_ROWS)
    } else {
      setSelectedRows(rows)
    }
  }, [rows, selectedRows])

  useEffect(() => {
    setSelectedRows(EMPTY_ROWS)
  }, [rows])

  return { selectedRows, selectRow, selectAllRows, isSelected }
}

export default useSelectedRows
