import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export type DialogCloseIconButtonProps = IconButtonProps & {
  tooltipProps?: TooltipProps
}

const DialogCloseIconButton = ({ tooltipProps, className: classNameProp, ...rest }: DialogCloseIconButtonProps) => {
  const className = twMerge('!absolute top-[12px] right-[12px]', classNameProp)

  return (
    <Tooltip title="Close" {...tooltipProps}>
      <IconButton {...rest} className={className}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  )
}

export default DialogCloseIconButton
