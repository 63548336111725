import { useState, useEffect } from 'react'

export type Params = {
  search?: string | null
  rowsPerPage: number
  page: number
}

const getFilteredRows = <TRow extends object>(rows: TRow[], search: Params['search']) =>
  !!search
    ? rows.filter((row) =>
        Object.values(row).reduce(
          (result, value) => result || String(value).toLowerCase().includes(search.toLowerCase()),
          false,
        ),
      )
    : rows

const getSlicedRows = <TRow extends object>(rows: TRow[], rowsPerPage: Params['rowsPerPage'], page: Params['page']) =>
  rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)

// TODO: Refactoring needed.
const useTableData = <TRow extends object>(rows: TRow[], { search, rowsPerPage, page }: Params) => {
  const [filteredRows, setFilteredRows] = useState(() => getFilteredRows(rows, search))
  const [slicedRows, setSlicedRows] = useState(() => getSlicedRows(filteredRows, rowsPerPage, page))
  const [tableData, setTableData] = useState({
    rows: slicedRows,
    total: filteredRows.length,
    pagesCount: Math.ceil(filteredRows.length / rowsPerPage),
    page,
  })

  useEffect(() => {
    setFilteredRows(getFilteredRows(rows, search))
  }, [rows, search])

  useEffect(() => {
    setSlicedRows(getSlicedRows(filteredRows, rowsPerPage, page))
  }, [filteredRows, rowsPerPage, page])

  useEffect(() => {
    setTableData({
      rows: slicedRows,
      total: filteredRows.length,
      pagesCount: Math.ceil(filteredRows.length / rowsPerPage),
      page,
    })
  }, [filteredRows, slicedRows, rowsPerPage, page])

  return tableData
}

export default useTableData
