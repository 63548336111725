import React from 'react'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { RawFilter, Filter } from '../types'
import { Order } from '../enums'

// TODO: Move utilities to index.ts.

// Common utilities.

export const formatDate = (date: string | number, format = 'DD/MM/YYYY') => dayjs(date).format(format)

export const copyToClipboard = (text?: string | null) => {
  if (!text) return

  navigator.clipboard.writeText(text).then(() => toast('Copied to clipboard.', { type: 'success' }))
}

// TODO: File name should be a variable (perhaps, file format too).
export const downloadImage = (image: string) => {
  const a = document.createElement('a')

  a.href = `data:image/jpg;base64,${image}`
  a.download = `Screenshot_${dayjs().toISOString()}.jpg`

  a.click()
}

// TODO: File name should be a variable.
export const downloadText = (text: string) => {
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })

  const a = document.createElement('a')

  a.href = URL.createObjectURL(blob)
  a.download = `Log_${dayjs().toISOString()}.txt`

  document.body.appendChild(a)

  a.click()

  document.body.removeChild(a)

  URL.revokeObjectURL(a.href)
}

export const downloadFile = (blobPart: BlobPart, filename: string) => {
  const blob = new Blob([blobPart])

  const a = document.createElement('a')

  a.href = URL.createObjectURL(blob)
  a.download = filename

  document.body.appendChild(a)

  a.click()

  document.body.removeChild(a)

  URL.revokeObjectURL(a.href)
}

export const handleImageError = (e: React.ChangeEvent<HTMLImageElement>, fallback = '/images/android.png') => {
  e.target.src = fallback
}

export const compareVersions = (versionA: string, versionB: string) => {
  const vA = versionA
    .split('.')
    .map((s) => s.padStart(10))
    .join('.')

  const vB = versionB
    .split('.')
    .map((s) => s.padStart(10))
    .join('.')

  return vA >= vB
}

// Coercing utilities.

export const toString = (value: any) => value?.toString() ?? ''

export const toNonEmptyStringOrNull = (value: any): string | null => value?.toString() || null

export const toNumberOrNull = (value: any): number | null => {
  const parsed = parseInt(value, 10)

  return !isNaN(parsed) ? parsed : null
}

// URL search params utilities.

export const serializeUrlSearchParams = (searchParams: Filter) => {
  const result = new URLSearchParams()

  Object.entries(searchParams).forEach(([key, value]) => {
    if (value === null) {
      return
    } else if (Array.isArray(value)) {
      value.forEach((_value: string | number) => result.append(key, String(_value)))
    } else {
      result.set(key, String(value))
    }
  })

  return result
}

export const urlSearchParamsToObject = (searchParams: URLSearchParams) => {
  const result = {}

  for (const [key, value] of searchParams.entries()) {
    // Removes empty strings and duplicates.

    if (!value) continue

    if (result.hasOwnProperty(key)) {
      if (!result[key].includes(value)) {
        result[key] = [...result[key], value]
      }
    } else {
      result[key] = [value]
    }
  }

  return result as RawFilter
}

export const deserializeStringArrayParam = (values?: string[], allowed?: string[]) => {
  if (!values) return null

  let result = values

  if (!!allowed) {
    result = result.filter((value) => allowed.includes(value))
  }

  if (result.length === 0) return null

  return result
}

export const deserializeStringParam = (values?: string[], allowed?: string[]) => {
  const result = deserializeStringArrayParam(values, allowed)

  if (!result) return null

  return result[0]
}

export const deserializeNumberArrayParam = (values?: string[], allowed?: number[]) => {
  if (!values) return null

  let result = values.map(toNumberOrNull).filter((value) => value !== null) as number[]

  if (!!allowed) {
    result = result.filter((value) => allowed.includes(value))
  }

  if (result.length === 0) return null

  return result
}

export const deserializeNumberParam = (values?: string[], allowed?: number[]) => {
  const result = deserializeNumberArrayParam(values, allowed)

  if (!result) return null

  return result[0]
}

// TODO: deserializeBooleanParam()

export const deserializePage = (raw: RawFilter) => deserializeNumberParam(raw.page) ?? 1

export const deserializeOrderBy = (raw: RawFilter, sortKeys: string[]) =>
  deserializeStringParam(raw.orderBy, sortKeys) || sortKeys[0] || 'created_at'

export const deserializeOrder = (raw: RawFilter, defaultOrder = Order.ASC) =>
  (deserializeStringParam(raw.order, [Order.ASC, Order.DESC]) as Order) || defaultOrder
