import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import {
  INotification,
  GetNotificationsParams,
  CreateNotificationParams,
  UpdateNotificationParams,
} from '../models/notification'
import { IDeviceProfile, GetDeviceProfilesParams } from '../models/device-profile'
import { IDevice, GetDevicesParams } from '../models/device'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-device-notifications`

export const getNotifications = ({
  name,
  type,
  title,
  frequency,
  orderBy,
  order,
  limit,
  page,
}: GetNotificationsParams = {}) =>
  axios.get<IResponse<ITableResource<INotification>>>(apiPrefix, {
    params: {
      'filter[name]': name,
      'filter[type]': type,
      'filter[title]': title,
      'filter[frequency]': frequency,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const getNotificationById = (id: string) => axios.get<IResponse<INotification>>(`${apiPrefix}/${id}`)

export const createNotification = (data: CreateNotificationParams) =>
  axios.post<IResponse<INotification>>(apiPrefix, data)

export const updateNotification = ({ id, ...data }: UpdateNotificationParams) =>
  axios.put<IResponse<INotification>>(`${apiPrefix}/${id}`, data)

export const deleteNotifications = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })

export const getNotificationDeviceProfiles = ({
  id,
  name,
  manufacturer,
  model,
  playback_support_id,
  status,
  orderBy,
  order,
  limit,
  page,
}: { id: string } & GetDeviceProfilesParams) =>
  axios.get<IResponse<ITableResource<IDeviceProfile>>>(`${apiPrefix}/${id}/device-profiles`, {
    params: {
      'filter[name]': name,
      'filter[manufacturer]': manufacturer,
      'filter[model]': model,
      'filter[playback_support_id]': playback_support_id?.join(','),
      'filter[status]': status,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const addDeviceProfilesToNotification = ({ id, ...data }: { id: string; operator_device_type_ids: string[] }) =>
  axios.post<IResponse<null>>(`${apiPrefix}/${id}/device-profiles`, data)

export const removeDeviceProfilesFromNotification = ({ id, ...data }: { id: string; ids: string[] }) =>
  axios.delete<IResponse<null>>(`${apiPrefix}/${id}/device-profiles`, { data })

export const getNotificationDevices = ({
  id,
  uid,
  provision_id,
  operator_device_type_id,
  name,
  serial_number,
  mac_address,
  release_cycle,
  status,
  lock,
  orderBy,
  order,
  limit,
  page,
}: { id: string } & GetDevicesParams) =>
  axios.get<IResponse<ITableResource<IDevice>>>(`${apiPrefix}/${id}/devices`, {
    params: {
      'filter[uid]': uid,
      'filter[provision_id]': provision_id,
      'filter[operator_device_type_id]': operator_device_type_id,
      'filter[name]': name,
      'filter[serial_number]': serial_number,
      'filter[mac_address]': mac_address,
      'filter[release_cycle]': release_cycle,
      'filter[status]': status,
      'filter[lock]': lock,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

// export const addDevicesToNotification = ({ id, ...data }: { id: string; operator_device_ids: string[] }) =>
//   axios.post<IResponse<null>>(`${apiPrefix}/${id}/devices`, data)

export const removeDevicesFromNotification = ({ id, ...data }: { id: string; ids: string[] }) =>
  axios.delete<IResponse<null>>(`${apiPrefix}/${id}/devices`, { data })
