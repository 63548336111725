import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource, ITableResourceApps, ISelectOption } from '../interfaces'
import {
  IDeviceProfile,
  GetDeviceProfilesParams,
  CreateDeviceProfileParams,
  UpdateDeviceProfileParams,
  IApp,
  GetAppsParams,
  CreateAppParams,
  UpdateAppParams,
  IAppPermissions,
  GetAppPermissionsParams,
  CreateAppPermissionsParams,
  UpdateAppPermissionsParams,
  IDeviceProfileROMs,
  UpdateDeviceProfileROMsParams,
  IDeviceProfileConfigurations,
  UpdateDeviceProfileConfigurationsParams,
} from '../models/device-profile'
import { INotification, GetNotificationsParams } from '../models/notification'
import { INetwork, CreateNetworkParams, UpdateNetworkParams } from '../models/network'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-device-types`
const apiPrefixDeviceProfile = `${CORE_API_DOMAIN}/device-profile`
const apiPrefixDeviceProfileConfigs = `${CORE_API_DOMAIN}/operator-device-type-configs`

export const getDeviceProfiles = ({
  name,
  manufacturer,
  model,
  playback_support_id,
  status,
  orderBy,
  order,
  limit,
  page,
}: GetDeviceProfilesParams = {}) =>
  axios.get<IResponse<ITableResource<IDeviceProfile>>>(apiPrefix, {
    params: {
      'filter[name]': name,
      'filter[manufacturer]': manufacturer,
      'filter[model]': model,
      'filter[playback_support_id]': playback_support_id?.join(','),
      'filter[status]': status,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const getDeviceProfileById = (id: string) => axios.get<IResponse<IDeviceProfile>>(`${apiPrefix}/${id}`)

export const createDeviceProfile = (data: CreateDeviceProfileParams) =>
  axios.postForm<IResponse<IDeviceProfile>>(apiPrefix, data)

export const updateDeviceProfile = ({ id, ...data }: UpdateDeviceProfileParams) =>
  axios.postForm<IResponse<IDeviceProfile>>(`${apiPrefix}/${id}`, data)

export const deleteDeviceProfiles = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })

export const getDeviceProfilePlaybackSupports = () =>
  axios.get<IResponse<{ data: ISelectOption[] }>>(
    `${CORE_API_DOMAIN}/column-statuses/OperatorDeviceType/playback_supports`,
  )

export const getApps = ({ operator_device_type_id, limit, page }: GetAppsParams) =>
  axios.get<{ data: ITableResourceApps<IApp> }>(`${apiPrefixDeviceProfile}/apps`, {
    params: {
      'filter[operator_device_type_id]': operator_device_type_id,
      limit,
      page,
    },
  })

export const getAppById = (id: string) => axios.get<{ data: IApp }>(`${apiPrefixDeviceProfile}/apps/${id}`)

export const createApp = (data: CreateAppParams) => axios.post<{ data: IApp }>(`${apiPrefixDeviceProfile}/apps`, data)

export const updateApp = ({ id, ...data }: UpdateAppParams) =>
  axios.put<{ data: IApp }>(`${apiPrefixDeviceProfile}/apps/${id}`, data)

export const deleteApp = (id: string) => axios.delete(`${apiPrefixDeviceProfile}/apps/${id}`)

export const getAppPermissions = ({ operator_device_type_id, limit, page }: GetAppPermissionsParams) =>
  axios.get<IResponse<ITableResource<IAppPermissions>>>(`${apiPrefixDeviceProfile}/apps/permissions`, {
    params: {
      'filter[operator_device_type_id]': operator_device_type_id,
      limit,
      page,
    },
  })

export const createAppPermissions = (data: CreateAppPermissionsParams) =>
  axios.post<IResponse<IAppPermissions>>(`${apiPrefixDeviceProfile}/apps/permissions`, data)

export const updateAppPermissions = ({ id, ...data }: UpdateAppPermissionsParams) =>
  axios.put<IResponse<IAppPermissions>>(`${apiPrefixDeviceProfile}/apps/permissions/${id}`, data)

export const deleteAppPermissions = (id: string) => axios.delete(`${apiPrefixDeviceProfile}/apps/permissions/${id}`)

export const getDeviceProfileNotifications = ({
  id,
  name,
  type,
  title,
  frequency,
  orderBy,
  order,
  limit,
  page,
}: { id: string } & GetNotificationsParams) =>
  axios.get<IResponse<ITableResource<INotification>>>(`${apiPrefix}/${id}/notifications`, {
    params: {
      'filter[name]': name,
      'filter[type]': type,
      'filter[title]': title,
      'filter[frequency]': frequency,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const addNotificationsToDeviceProfile = ({
  id,
  ...data
}: {
  id: string
  operator_device_notification_ids: string[]
}) => axios.post<IResponse<null>>(`${apiPrefix}/${id}/notifications`, data)

export const removeNotificationsFromDeviceProfile = ({ id, ...data }: { id: string; ids: string[] }) =>
  axios.delete<IResponse<null>>(`${apiPrefix}/${id}/notifications`, { data })

export const getDeviceProfileROMs = (id: string) =>
  axios.get<{ data: IDeviceProfileROMs }>(`${apiPrefixDeviceProfile}/${id}/roms`)

export const updateDeviceProfileROMs = ({ id, ...data }: UpdateDeviceProfileROMsParams) =>
  axios.put<IResponse<unknown>>(`${apiPrefixDeviceProfile}/${id}/roms`, data)

export const getDeviceProfileNetwork = (id: string) =>
  axios.get<IResponse<INetwork | null>>(`${apiPrefix}/${id}/network-configuration`)

export const createDeviceProfileNetwork = ({ id, ...data }: { id: string } & CreateNetworkParams) =>
  axios.post<unknown>(`${apiPrefix}/${id}/network-configuration`, data)

export const updateDeviceProfileNetwork = ({ id, ...data }: { id: string } & UpdateNetworkParams) =>
  axios.put<unknown>(`${apiPrefix}/${id}/network-configuration`, data)

export const deleteDeviceProfileNetwork = (id: string) =>
  axios.delete<unknown>(`${apiPrefix}/${id}/network-configuration`)

export const getDeviceProfileConfigurations = (id: string) =>
  axios.get<{ data: IDeviceProfileConfigurations }>(`${apiPrefixDeviceProfileConfigs}/${id}`)

export const updateDeviceProfileConfigurations = ({ id, ...data }: UpdateDeviceProfileConfigurationsParams) =>
  axios.put<IResponse<unknown>>(`${apiPrefixDeviceProfileConfigs}/${id}`, data)
