import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import {
  IDevice,
  IDeviceData,
  GetDevicesFilter,
  GetDevicesParams,
  CreateDeviceParams,
  UpdateDeviceParams,
  ImportDevicesParams,
  IAgnosticDevice,
  GetAgnosticDevicesParams,
  ImportAgnosticDevicesParams,
} from '../models/device'
import { INetwork, CreateNetworkParams, UpdateNetworkParams } from '../models/network'
import { INotification, GetNotificationsParams } from '../models/notification'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-devices`
const apiPrefixAgnostic = `${CORE_API_DOMAIN}/backend-configurable-adm`

export const getDevices = ({
  uid,
  provision_id,
  operator_device_type_id,
  name,
  serial_number,
  mac_address,
  release_cycle,
  status,
  lock,
  orderBy,
  order,
  limit,
  page,
}: GetDevicesParams = {}) =>
  axios.get<IResponse<ITableResource<IDevice>>>(apiPrefix, {
    params: {
      'filter[uid]': uid,
      'filter[provision_id]': provision_id,
      'filter[operator_device_type_id]': operator_device_type_id,
      'filter[name]': name,
      'filter[serial_number]': serial_number,
      'filter[mac_address]': mac_address,
      'filter[release_cycle]': release_cycle,
      'filter[status]': status,
      'filter[lock]': lock,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const getDeviceById = (id: string) => axios.get<IResponse<IDevice>>(`${apiPrefix}/${id}`)

export const getDeviceByPairingCode = (pairing_code: string) =>
  axios.get<IResponse<ITableResource<IDevice>>>(apiPrefix, {
    params: {
      'filter[pairing_code]': pairing_code,
    },
  })

export const createDevice = (data: CreateDeviceParams) => axios.post<IResponse<IDevice>>(apiPrefix, data)

export const updateDevice = ({ id, ...data }: UpdateDeviceParams) =>
  axios.put<IResponse<IDevice>>(`${apiPrefix}/${id}`, data)

export const lockDevice = ({ id, ...data }: { id: string; lock: 1 | 0 }) =>
  axios.patch<IResponse<IDevice>>(`${apiPrefix}/${id}/lock`, data)

export const deleteDevices = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })

export const importDevices = (data: ImportDevicesParams) =>
  axios.postForm<IResponse<string>>(`${apiPrefix}/import`, data)

export const exportDevices = ({
  uid,
  provision_id,
  operator_device_type_id,
  name,
  serial_number,
  mac_address,
  release_cycle,
  status,
  lock,
}: GetDevicesFilter = {}) =>
  axios.get<string>(`${apiPrefix}/export`, {
    params: {
      'filter[uid]': uid,
      'filter[provision_id]': provision_id,
      'filter[operator_device_type_id]': operator_device_type_id,
      'filter[name]': name,
      'filter[serial_number]': serial_number,
      'filter[mac_address]': mac_address,
      'filter[release_cycle]': release_cycle,
      'filter[status]': status,
      'filter[lock]': lock,
    },
  })

export const getDeviceData = (id: string) => axios.get<IResponse<IDeviceData>>(`${apiPrefix}/data/${id}`)

export const getDeviceStatusV2 = (id: string) => axios.get<IResponse<object>>(`${apiPrefix}/status/${id}`)

export const getDeviceStatus = (mac: string) =>
  axios.get<IResponse<ITableResource<object>>>(`${CORE_API_DOMAIN}/query/status`, {
    params: {
      'list[]': true,
      mac_address_values: mac,
      order: 'updated_at',
      direction: 'desc',
    },
  })

export const getDeviceNetwork = (id: string) =>
  axios.get<IResponse<INetwork | null>>(`${apiPrefix}/${id}/network-configuration`)

export const createDeviceNetwork = ({ id, ...data }: { id: string } & CreateNetworkParams) =>
  axios.post<unknown>(`${apiPrefix}/${id}/network-configuration`, data)

export const updateDeviceNetwork = ({ id, ...data }: { id: string } & UpdateNetworkParams) =>
  axios.put<unknown>(`${apiPrefix}/${id}/network-configuration`, data)

export const deleteDeviceNetwork = (id: string) => axios.delete<unknown>(`${apiPrefix}/${id}/network-configuration`)

export const getDeviceNotifications = ({
  id,
  name,
  type,
  title,
  frequency,
  orderBy,
  order,
  limit,
  page,
}: { id: string } & GetNotificationsParams) =>
  axios.get<IResponse<ITableResource<INotification>>>(`${apiPrefix}/${id}/notifications`, {
    params: {
      'filter[name]': name,
      'filter[type]': type,
      'filter[title]': title,
      'filter[frequency]': frequency,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const addNotificationsToDevice = ({ id, ...data }: { id: string; operator_device_notification_ids: string[] }) =>
  axios.post<IResponse<null>>(`${apiPrefix}/${id}/notifications`, data)

export const removeNotificationsFromDevice = ({ id, ...data }: { id: string; ids: string[] }) =>
  axios.delete<IResponse<null>>(`${apiPrefix}/${id}/notifications`, { data })

export const getAgnosticDevices = ({
  operator_device_type_id,
  mac_address,
  status,
  limit,
  page,
}: GetAgnosticDevicesParams = {}) =>
  axios.get<IResponse<ITableResource<IAgnosticDevice>>>(apiPrefixAgnostic, {
    params: {
      'filter[operator_device_type_id]': operator_device_type_id,
      'filter[mac_address]': mac_address,
      'filter[status]': status,
      limit,
      page,
    },
  })

export const importAgnosticDevices = (data: ImportAgnosticDevicesParams) =>
  axios.postForm<IResponse<string>>(apiPrefixAgnostic, data)
