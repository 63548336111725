import { ISelectOption } from './interfaces'
import {
  RoleId,
  RemoteActionId,
  Position,
  Period,
  NotificationType,
  NotificationFrequency,
  AlarmType,
  NetworkSecurity,
} from './enums'

// TODO: Reorganize this file.

const {
  SYS_OWNER,
  SYS_ADMIN,
  OPR_OWNER,
  OPR_ADMIN,
  OPR_EDITOR,
  OPR_VIEWER,
  OPR_SUPPORT_LINE_1,
  OPR_SUPPORT_LINE_2,
  //
} = RoleId

export const systemRoles: string[] = [
  SYS_OWNER,
  SYS_ADMIN,
  //
]

export const operatorRoles: string[] = [
  OPR_OWNER,
  OPR_ADMIN,
  OPR_EDITOR,
  OPR_VIEWER,
  OPR_SUPPORT_LINE_1,
  OPR_SUPPORT_LINE_2,
]

export const positionMap = {
  [Position.TOP_LEFT]: 'Top Left',
  [Position.TOP_CENTER]: 'Top Center',
  [Position.TOP_RIGHT]: 'Top Right',
  [Position.CENTER_LEFT]: 'Center Left',
  [Position.CENTER]: 'Center',
  [Position.CENTER_RIGHT]: 'Center Right',
  [Position.BOTTOM_LEFT]: 'Bottom Left',
  [Position.BOTTOM_CENTER]: 'Bottom Center',
  [Position.BOTTOM_RIGHT]: 'Bottom Right',
}

export const positions: ISelectOption[] = [
  { id: Position.TOP_LEFT, name: positionMap[Position.TOP_LEFT] },
  { id: Position.TOP_CENTER, name: positionMap[Position.TOP_CENTER] },
  { id: Position.TOP_RIGHT, name: positionMap[Position.TOP_RIGHT] },
  { id: Position.CENTER_LEFT, name: positionMap[Position.CENTER_LEFT] },
  { id: Position.CENTER, name: positionMap[Position.CENTER] },
  { id: Position.CENTER_RIGHT, name: positionMap[Position.CENTER_RIGHT] },
  { id: Position.BOTTOM_LEFT, name: positionMap[Position.BOTTOM_LEFT] },
  { id: Position.BOTTOM_CENTER, name: positionMap[Position.BOTTOM_CENTER] },
  { id: Position.BOTTOM_RIGHT, name: positionMap[Position.BOTTOM_RIGHT] },
]

export const periodMap = {
  [Period.HOURLY]: 'Hourly',
  [Period.DAILY]: 'Daily',
  [Period.WEEKLY]: 'Weekly',
  [Period.MONTHLY]: 'Monthly',
}

export const periods: ISelectOption[] = [
  { id: Period.HOURLY, name: periodMap[Period.HOURLY] },
  { id: Period.DAILY, name: periodMap[Period.DAILY] },
  { id: Period.WEEKLY, name: periodMap[Period.WEEKLY] },
  { id: Period.MONTHLY, name: periodMap[Period.MONTHLY] },
]

export const notificationTypeMap = {
  [NotificationType.POP_UP]: 'Pop-up',
  [NotificationType.FULLSCREEN]: 'Fullscreen',
}

export const notificationTypes: ISelectOption[] = [
  { id: NotificationType.POP_UP, name: notificationTypeMap[NotificationType.POP_UP] },
  { id: NotificationType.FULLSCREEN, name: notificationTypeMap[NotificationType.FULLSCREEN] },
]

export const notificationFrequencyMap = {
  [NotificationFrequency.ONCE]: 'Once',
  [NotificationFrequency.ON_BOOT]: 'On Boot',
  [NotificationFrequency.SCHEDULED]: 'Scheduled',
}

export const notificationFrequencies: ISelectOption[] = [
  { id: NotificationFrequency.ONCE, name: notificationFrequencyMap[NotificationFrequency.ONCE] },
  { id: NotificationFrequency.ON_BOOT, name: notificationFrequencyMap[NotificationFrequency.ON_BOOT] },
  { id: NotificationFrequency.SCHEDULED, name: notificationFrequencyMap[NotificationFrequency.SCHEDULED] },
]

export const instantNotificationFrequencies: ISelectOption[] = [
  { id: NotificationFrequency.ONCE, name: notificationFrequencyMap[NotificationFrequency.ONCE] },
]

export const scheduledNotificationFrequencies: ISelectOption[] = [
  { id: NotificationFrequency.ON_BOOT, name: notificationFrequencyMap[NotificationFrequency.ON_BOOT] },
  { id: NotificationFrequency.SCHEDULED, name: notificationFrequencyMap[NotificationFrequency.SCHEDULED] },
]

export const networkSecurityMap = {
  [NetworkSecurity.WEP]: 'WEP',
  [NetworkSecurity.WPA]: 'WPA',
}

export const networkSecurities: ISelectOption[] = [
  { id: NetworkSecurity.WEP, name: networkSecurityMap[NetworkSecurity.WEP] },
  { id: NetworkSecurity.WPA, name: networkSecurityMap[NetworkSecurity.WPA] },
]

export const activityStatuses: ISelectOption[] = [
  { id: 1, name: 'Active' },
  { id: 0, name: 'Inactive' },
]

export const confirmStatuses: ISelectOption[] = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' },
]

export const equalityChecks: ISelectOption[] = [
  { id: 'EQUAL', name: 'Equal' },
  { id: 'NOT_EQUAL', name: 'Not equal' },
  { id: 'LESS', name: 'Less' },
  { id: 'LESS_OR_EQUAL', name: 'Less OR equal' },
  { id: 'MORE', name: 'More' },
  { id: 'MORE_OR_EQUAL', name: 'More OR equal' },
]

export const releaseCycles: ISelectOption[] = [
  { id: 0, name: 'Development' },
  { id: 1, name: 'Quality Assurance' },
  { id: 2, name: 'Alpha' },
  { id: 3, name: 'Beta' },
  { id: 4, name: 'Release Candidate' },
  { id: 5, name: 'Release' },
]

export const lockStatuses: ISelectOption[] = [
  { id: 0, name: 'Unlocked' },
  { id: 1, name: 'Locked' },
]

export const lockReasons: ISelectOption[] = [
  { id: 0, name: 'Grace period' },
  { id: 1, name: 'USB debug mode enabled' },
  { id: 2, name: 'Bootloader unlocked' },
  { id: 3, name: 'Developer mode enabled' },
  { id: 4, name: 'Rooted' },
  { id: 5, name: 'Manual lock' },
  { id: 6, name: 'Locked by external app' },
  { id: 7, name: 'Locked by bulk action' },
]

export const launchTypes: ISelectOption[] = [
  { id: '0', name: 'Launcher' },
  { id: '1', name: 'Activity' },
  { id: '2', name: 'Service' },
]

export const alarmTypes: ISelectOption[] = [
  { id: AlarmType.PUSH, name: 'Push' },
  { id: AlarmType.OTA, name: 'OTA' },
  { id: AlarmType.INSTALL, name: 'Install' },
  { id: AlarmType.SOCKET, name: 'Socket' },
  { id: AlarmType.SPEEDTEST, name: 'Speedtest' },
  { id: AlarmType.SYNC, name: 'Sync' },
]

export const bulkActionStatuses: (ISelectOption & { color: string })[] = [
  {
    id: -1,
    name: 'Failed',
    color: 'bg-red',
  },
  {
    id: 0,
    name: 'Ready for sending',
    color: 'bg-slate-500',
  },
  {
    id: 1,
    name: 'Created',
    color: 'bg-slate-500',
  },
  {
    id: 2,
    name: 'Executed',
    color: 'bg-primary',
  },
  {
    id: 3,
    name: 'Canceled',
    color: 'bg-red',
  },
  {
    id: 4,
    name: 'Completed',
    color: 'bg-green-500',
  },
  {
    id: 100,
    name: 'Sending',
    color: 'bg-yellow-500',
  },
]

export const userRoleMap = {
  [SYS_OWNER]: 'System Owner',
  [SYS_ADMIN]: 'System Admin',
  [OPR_OWNER]: 'Operator Owner',
  [OPR_ADMIN]: 'Operator Admin',
  [OPR_EDITOR]: 'Operator Editor',
  [OPR_VIEWER]: 'Operator Viewer',
  [OPR_SUPPORT_LINE_1]: 'Operator Support Line 1',
  [OPR_SUPPORT_LINE_2]: 'Operator Support Line 2',
}

export const alarmErrorMap = {
  [AlarmType.PUSH]: {
    0: 'Registration',
    1: 'Registration',
    2: 'Registration',
  },
  [AlarmType.OTA]: {
    0: 'Download',
    1: 'Install',
    2: 'Low Storage',
  },
  [AlarmType.INSTALL]: {
    0: 'Download',
    1: 'Install',
    2: 'Low Storage',
  },
  [AlarmType.SOCKET]: {
    0: 'Connection',
    1: 'Connection',
    2: 'Connection',
  },
  [AlarmType.SPEEDTEST]: {
    0: 'Connection',
    1: 'Connection',
    2: 'Connection',
  },
}

export const remoteAssistanceMessageMap = {
  SESSION_CLOSED: 'Session closed.',
  REMOTE_SUPPORT_DISABLED: "Couldn't connect to the device because its device profile has remote support disabled.",
  PUSH_NOTIFICATIONS_DISABLED: "Couldn't connect to the device because push notifications or debug mode are disabled.",
  CONTROL_VERSION_NOT_SUPPORTED:
    "Couldn't connect to the device because it has a deprecated version of the device control app installed.",
  OPEN_SOCKET_ERROR: "Couldn't reach the device.",

  PENDING: 'Connecting to the device. Please wait a moment...',
  ACCESS_REQUESTED: 'Access to the device has been requested. Please wait a moment...',
  ACCESS_DENIED: 'Access to the device has been denied. Please contact the user and try again.',
  TIMEOUT: 'Connection timeout. The device did not respond in time. Please try again.',
  ERROR: 'Connection error. Please check your network and try again.',
  DISCONNECTED: 'Connection closed. Please check your network and try again.',
}

export const remoteAssistanceUserActionMap = {
  [RemoteActionId.KEY_PRESS]: 'Key Press',
  [RemoteActionId.KEY_PRESS_LEFT]: 'Key Press Left',
  [RemoteActionId.KEY_PRESS_RIGHT]: 'Key Press Right',
  [RemoteActionId.KEY_PRESS_BACK]: 'Key Press Back',
  [RemoteActionId.KEY_PRESS_APPS]: 'Key Press Apps',
  [RemoteActionId.KEY_PRESS_MUTE]: 'Key Press Mute',
  [RemoteActionId.KEY_PRESS_DPAD_UP]: 'Key Press D-pad Up',
  [RemoteActionId.KEY_PRESS_DPAD_DOWN]: 'Key Press D-pad Down',
  [RemoteActionId.KEY_PRESS_DPAD_LEFT]: 'Key Press D-pad Left',
  [RemoteActionId.KEY_PRESS_DPAD_RIGHT]: 'Key Press D-pad Right',
  [RemoteActionId.KEY_PRESS_DPAD_CENTER]: 'Key Press D-pad Center',
  [RemoteActionId.TAP_SCREEN]: 'Tap Screen',
  [RemoteActionId.INPUT_TEXT]: 'Input Text',
  [RemoteActionId.DOWNLOAD_SCREENSHOT]: 'Download Screenshot',
  [RemoteActionId.INSTALL_APP]: 'Install App',
  [RemoteActionId.OPEN_APP]: 'Open App',
  [RemoteActionId.UNINSTALL_APP]: 'Uninstall App',
  [RemoteActionId.REBOOT]: 'Reboot',
  [RemoteActionId.LOCK_UNLOCK]: 'Lock/Unlock',
  [RemoteActionId.DISPLAY_ON_OFF]: 'Display On/Off',
  [RemoteActionId.LOG]: 'Download Log',
  [RemoteActionId.ADB]: 'ADB Command',
  [RemoteActionId.ADB_SHELL]: 'ADB Shell Command',
  [RemoteActionId.CHECK_OTA_UPDATES]: 'Check OTA Updates',
  [RemoteActionId.KEY_PRESS_HOME]: 'Key Press Home',
  [RemoteActionId.CLEAR_TEXT]: 'Clear Text',
  [RemoteActionId.VOLUME]: 'Volume',
  [RemoteActionId.CLEAR_DATA]: 'Clear Data',
  [RemoteActionId.RESET]: 'Factory Reset',
  [RemoteActionId.KEY_PRESS_SETTINGS]: 'Key Press Settings',
  [RemoteActionId.NOTIFICATION]: 'Notification',
  [RemoteActionId.NOTIFICATION_REMOVE]: 'Notification Remove',
  [RemoteActionId.SCHEDULED_NOTIFICATION_ADD]: 'Add Scheduled Notification',
  [RemoteActionId.SCHEDULED_NOTIFICATION_REMOVE]: 'Remove Scheduled Notification',
  [RemoteActionId.RELEASE_CYCLE]: 'Change Release Cycle',
  [RemoteActionId.DEVICE_LOCK]: 'Device Lock',
  [RemoteActionId.DEVICE_UNLOCK]: 'Device Unlock',
  [RemoteActionId.AUTO_NETWORK_CONFIG]: 'Change Network Config',
}
