import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { TableContainer, Table as TableUI, TableBody, TableRow, TableCell, Checkbox, Typography } from '@mui/material'
import InboxIcon from '@mui/icons-material/Inbox'
import type { TableColumn } from 'common/types'
import { useDialog, useSelectedRows } from 'hooks'
import TableSettingsDialog from './TableSettingsDialog'
import TableToolbar, { TableToolbarProps } from './TableToolbar'
import TableHead, { TableHeadProps } from './TableHead'
import TablePagination, { TablePaginationProps } from './TablePagination'

export type TableProps<T> = {
  id?: string
  getKey?: (row: T) => string
  columns: TableColumn<T>[]
  rows: T[]
  total?: number
  showSettings?: boolean
} & Pick<TableToolbarProps<T>, 'title' | 'renderActions' | 'renderSelectedRowsActions'> &
  Pick<TableHeadProps<T>, 'orderBy' | 'order' | 'onOrderByChange' | 'onOrderChange' | 'checkboxSelection'> &
  Partial<TablePaginationProps>

const Table = <T,>({
  id,
  getKey,
  title,
  renderActions,
  renderSelectedRowsActions,
  columns,
  rows,
  orderBy,
  order,
  onOrderByChange,
  onOrderChange,
  checkboxSelection = false,
  total,
  pagesCount,
  page,
  onPageChange,
  showSettings = false,
}: TableProps<T>) => {
  const [columnsVisibility, setColumnsVisibility] = useState<Record<string, boolean>>(() => {
    const defaultColumnsVisibility = columns.reduce(
      (acc, { id, hiddenByDefault }) => ({ ...acc, [id]: !hiddenByDefault }),
      {},
    )

    if (showSettings && !!id) {
      const json = localStorage.getItem(id)
      const settings = !!json ? JSON.parse(json) : null

      if (!!settings) {
        return columns.reduce((acc, { id }) => {
          if (settings.columnsVisibility.hasOwnProperty(id)) {
            return { ...acc, [id]: settings.columnsVisibility[id] }
          }

          return acc
        }, defaultColumnsVisibility)
      }
    }

    return defaultColumnsVisibility
  })

  const visibleColumns = useMemo(() => columns.filter(({ id }) => columnsVisibility[id]), [columns, columnsVisibility])

  const openDialog = useDialog()

  const handleSettingsClick = useCallback(
    () =>
      openDialog<Record<string, boolean>>(
        (dialogProps) => (
          <TableSettingsDialog {...dialogProps} columns={columns} columnsVisibility={columnsVisibility} />
        ),
        { maxWidth: 'sm', scroll: 'paper' },
      )
        .then(setColumnsVisibility)
        .catch(() => null),
    [openDialog, columns, columnsVisibility],
  )

  useEffect(() => {
    if (showSettings && !!id) {
      const json = localStorage.getItem(id)
      const settings = !!json ? JSON.parse(json) : null

      localStorage.setItem(
        id,
        JSON.stringify({
          columnsVisibility: { ...settings?.columnsVisibility, ...columnsVisibility },
        }),
      )
    }
  }, [showSettings, id, columnsVisibility])

  const { selectedRows, selectRow, selectAllRows, isSelected } = useSelectedRows(rows)

  const rowsCount = rows.length
  const selectedRowsCount = selectedRows.length

  const isToolbarVisible =
    !!title || !!renderActions || (!!renderSelectedRowsActions && selectedRowsCount > 0) || showSettings
  const isPaginationVisible = !!onPageChange

  const tableBody = useMemo(
    () => (
      <TableBody>
        {rowsCount === 0 && (
          <TableRow>
            <TableCell colSpan={visibleColumns.length + Number(checkboxSelection)}>
              <div className="flex flex-col items-center gap-1">
                <InboxIcon className="!text-6xl" color="disabled" />
                <div className="text-trueGray-400">No data found</div>
              </div>
            </TableCell>
          </TableRow>
        )}
        {rows.map((row) => {
          const selected = isSelected(row)
          const onSelectRow = () => selectRow(row)

          return (
            <TableRow key={getKey ? getKey(row) : row['id']} selected={selected} hover>
              {checkboxSelection && (
                <TableCell padding="checkbox">
                  <Checkbox color="primary" checked={selected} onChange={onSelectRow} />
                </TableCell>
              )}
              {visibleColumns.map((column) => {
                const cellProps = {
                  align: column.align,
                  sx: { minWidth: column.width, maxWidth: column.width },
                }

                if (!!column.renderCell) {
                  return (
                    <TableCell key={column.id} {...cellProps}>
                      {column.renderCell(row)}
                    </TableCell>
                  )
                }

                const title = !!column.valueGetter ? column.valueGetter(row) : String(row[column.id] ?? '')

                return (
                  <TableCell key={column.id} {...cellProps}>
                    <div className="truncate" title={title}>
                      {title}
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    ),
    [getKey, rows, rowsCount, visibleColumns, checkboxSelection, isSelected, selectRow],
  )

  return (
    <>
      {isToolbarVisible && (
        <TableToolbar<T>
          title={title}
          renderActions={renderActions}
          renderSelectedRowsActions={renderSelectedRowsActions}
          selectedRows={selectedRows}
          onSettingsClick={showSettings ? handleSettingsClick : undefined}
        />
      )}
      <TableContainer>
        <TableUI>
          <TableHead<T>
            columns={visibleColumns}
            orderBy={orderBy}
            order={order}
            onOrderByChange={onOrderByChange}
            onOrderChange={onOrderChange}
            checkboxSelection={checkboxSelection}
            rowsCount={rowsCount}
            selectedRowsCount={selectedRowsCount}
            onSelectAllRows={selectAllRows}
          />
          {tableBody}
        </TableUI>
      </TableContainer>
      {isPaginationVisible && (
        <div className="flex justify-between items-center mt-4">
          <Typography component="div" variant="body1">
            Total: {total}
          </Typography>
          <TablePagination pagesCount={pagesCount} page={page} onPageChange={onPageChange} />
        </div>
      )}
    </>
  )
}

export default React.memo(Table) as <T>(props: TableProps<T>) => React.ReactElement
