import React, { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { CommonDialogProps, TableColumn } from 'common/types'
import DialogCloseIconButton from 'components/dialogs/dialog-close-icon-button'

export type TableSettingsDialogProps = CommonDialogProps<Record<string, boolean>> & {
  columns: TableColumn<any>[]
  columnsVisibility: Record<string, boolean>
}

const TableSettingsDialog = ({
  columns,
  columnsVisibility: columnsVisibilityProp,
  ...dialogProps
}: TableSettingsDialogProps) => {
  const [columnsVisibility, setColumnsVisibility] = useState<Record<string, boolean>>(columnsVisibilityProp)

  const toggleColumnVisibility = (id: string) => {
    setColumnsVisibility((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const resetColumnsVisibility = () => {
    setColumnsVisibility(columns.reduce((acc, { id, hiddenByDefault }) => ({ ...acc, [id]: !hiddenByDefault }), {}))
  }

  return (
    <>
      <DialogTitle>Settings</DialogTitle>
      <DialogCloseIconButton onClick={dialogProps.reject} />
      <DialogContent dividers>
        <DialogContentText>Show/hide columns:</DialogContentText>
        <FormGroup>
          {columns.map(({ id, label, alwaysVisible }) => (
            <FormControlLabel
              key={id}
              label={label}
              control={
                <Checkbox
                  checked={columnsVisibility[id]}
                  disabled={alwaysVisible}
                  onChange={() => toggleColumnVisibility(id)}
                />
              }
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<RefreshIcon />} onClick={resetColumnsVisibility}>
          Reset to Default
        </Button>
        <div className="flex grow" />
        <Button variant="contained" color="error" onClick={() => dialogProps.reject()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => dialogProps.resolve(columnsVisibility)}>
          OK
        </Button>
      </DialogActions>
    </>
  )
}

export default React.memo(TableSettingsDialog)
